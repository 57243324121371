import React, { useContext } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { UserContext } from "./context/LoginContext";
const Home = React.lazy(() => import("./components/pages/Home"));
const Jobs = React.lazy(() => import("./components/pages/Jobs"));
const Projects = React.lazy(() => import("./components/pages/Projects"));
const Companies = React.lazy(() => import("./components/pages/Companies"));
const Freelancers = React.lazy(() => import("./components/pages/Freelancers"));
const About = React.lazy(() => import("./components/pages/About"));
const SingleJob = React.lazy(() => import("./components/pages/SingleJob"));
const CategoryJobs = React.lazy(() => import("./components/pages/CategoryJobs"));
const Contact = React.lazy(() => import("./components/pages/Contactus"));
const SingleProject = React.lazy(() => import("./components/pages/SingleProject"));
const Signin = React.lazy(() => import("./components/pages/Signin"));
const Signup = React.lazy(() => import("./components/pages/Signup"));
const Verification = React.lazy(() => import("./components/pages/Verification"));
const SignupProfile = React.lazy(() => import("./components/pages/SignupProfile"));
const MyProfile = React.lazy(() => import("./components/pages/MyProfile"));
const EditProfile = React.lazy(() => import("./components/pages/EditProfile"));
const HelpCentre = React.lazy(() => import("./components/pages/HelpCentre"));
const Blog = React.lazy(() => import("./components/pages/Blog"));
const BlogSingle = React.lazy(() => import("./components/pages/BlogSingle"));
const Pricing = React.lazy(() => import("./components/pages/Pricing"));
const Checkout = React.lazy(() => import("./components/pages/Checkout"));
const Invoice = React.lazy(() => import("./components/pages/Invoice"));
const SubmitJob = React.lazy(() => import("./components/pages/SubmitJob"));
const SubmitProject = React.lazy(() => import("./components/pages/SubmitProject"));
const Privacy = React.lazy(() => import("./components/pages/Privacy"));
const Terms = React.lazy(() => import("./components/pages/Terms"));
const Dashboard = React.lazy(() => import("./components/pages/Dashboard"));
const ManageJobs = React.lazy(() => import("./components/pages/ManageJobs"));
const Portfolio = React.lazy(() => import("./components/pages/Portfolio"));
const Bookmarks = React.lazy(() => import("./components/pages/Bookmarks"));
const Notifications = React.lazy(() => import("./components/pages/Notifications"));
const Messages = React.lazy(() => import("./components/pages/Messages"));
const Reviews = React.lazy(() => import("./components/pages/Reviews"));
const Bids = React.lazy(() => import("./components/pages/Bids"));
const Payments = React.lazy(() => import("./components/pages/Payments"));
const CompanyProfile = React.lazy(() => import("./components/pages/CompanyProfile"));
const FreelancerProfile = React.lazy(() => import("./components/pages/FreelancerProfile"));
const EditJob = React.lazy(() => import("./components/pages/EditJob"));

const ForEmployeers = React.lazy(() => import("./components/pages/ForEmployeers"));
const ForJobSeekers = React.lazy(() => import("./components/pages/ForJobSeekers"));
const HireFreelancers = React.lazy(() => import("./components/pages/HireFreelancers"));
const HireFullTimeEmployees = React.lazy(() => import("./components/pages/HireFullTimeEmployees"));
const JobSearchingAssistance = React.lazy(() =>
    import("./components/pages/JobSearchingAssistance")
);
const PostJob = React.lazy(() => import("./components/pages/PostJob"));

const Events = React.lazy(() => import("./components/pages/Events"));
const SingleEvent = React.lazy(() => import("./components/pages/SingleEvent"));
const Cart = React.lazy(() => import("./components/pages/Cart"));

const HrServiceConsulting = React.lazy(() => import("./components/pages/HrServiceConsulting"));
export default function App() {
    const { isLoggedIn } = useContext(UserContext);
    return (
        <React.Suspense>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/us" element={<Home />} />
                <Route path="/jobs" element={<Jobs />} />
                <Route path="/browse-projects" element={<Projects />} />
                <Route path="/companies" element={<Companies />} />
                <Route path="/freelancers" element={<Freelancers />} />
                <Route path="/about" element={<About />} />
                <Route path="/job/:jobId" element={<SingleJob />} />
                <Route path="/cat/job/:catId" element={<CategoryJobs />} />
                <Route path="/single-project" element={<SingleProject />} />
                <Route path="/myprofile" element={<MyProfile />} />
                <Route path="/editprofile" element={<EditProfile />} />
                <Route path="/contact-us" element={<Contact />} />
                <Route path="/help" element={<HelpCentre />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/blog/:blogTitle" element={<BlogSingle />} />
                <Route path="/pricing" element={<Pricing />} />
                <Route path="/checkout" element={<Checkout />} />
                <Route path="/invoice" element={<Invoice />} />
                <Route path="/submit-job" element={<SubmitJob />} />
                <Route path="/submit-project" element={<SubmitProject />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/portfolio" element={<Portfolio />} />
                <Route path="/notifications" element={<Notifications />} />
                <Route path="/messages" element={<Messages />} />
                <Route path="/bookmarks" element={<Bookmarks />} />
                <Route path="/manage-jobs" element={<ManageJobs />} />
                <Route path="/bids" element={<Bids />} />
                <Route path="/reviews" element={<Reviews />} />
                <Route path="/payments" element={<Payments />} />
                <Route path="/company-profile" element={<CompanyProfile />} />
                <Route path="/freelancer-profile/:userId" element={<FreelancerProfile />} />
                <Route path="/edit-job" element={<EditJob />} />
                <Route
                    path="/sign-in"
                    element={isLoggedIn ? <Navigate to="/myprofile" /> : <Signin />}
                />
                <Route
                    path="/sign-up"
                    element={isLoggedIn ? <Navigate to="/myprofile" /> : <Signup />}
                />
                <Route path="/email-verification" element={<Verification />} />
                <Route path="/sign-up-profile" element={<SignupProfile />} />
                <Route path="/for-employers" element={<ForEmployeers />} />
                <Route path="/for-job-seekers" element={<ForJobSeekers />} />
                <Route path="/hire-temp-workers" element={<HireFreelancers />} />
                <Route path="/hire-fulltime-employees" element={<HireFullTimeEmployees />} />
                <Route path="/job-searching-assistance" element={<JobSearchingAssistance />} />
                <Route path="/post-a-job" element={<PostJob />} />
                <Route path="/events" element={<Events />} />
                <Route path="/event_details" element={<SingleEvent />} />
                <Route path="/cart" element={<Cart />} />
            </Routes>
        </React.Suspense>
    );
  return (
    <React.Suspense>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/us" element={<Home />} />
        <Route path="/jobs" element={<Jobs />} />
        <Route path="/browse-projects" element={<Projects />} />
        <Route path="/companies" element={<Companies />} />
        <Route path="/freelancers" element={<Freelancers />} />
        <Route path="/about" element={<About />} />
        <Route path="/job/:jobId" element={<SingleJob />} />
        <Route path="/category_jobs/:catId" element={<CategoryJobs />} />
        <Route path="/single-project" element={<SingleProject />} />
        <Route path="/myprofile" element={<MyProfile />} />
        <Route path="/editprofile" element={<EditProfile />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/help" element={<HelpCentre />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:blogTitle" element={<BlogSingle />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/invoice" element={<Invoice />} />
        <Route path="/submit-job" element={<SubmitJob />} />
        <Route path="/submit-project" element={<SubmitProject />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/notifications" element={<Notifications />} />
        <Route path="/messages" element={<Messages />} />
        <Route path="/bookmarks" element={<Bookmarks />} />
        <Route path="/manage-jobs" element={<ManageJobs />} />
        <Route path="/bids" element={<Bids />} />
        <Route path="/reviews" element={<Reviews />} />
        <Route path="/payments" element={<Payments />} />
        <Route path="/company-profile" element={<CompanyProfile />} />
        <Route
          path="/freelancer-profile/:userId"
          element={<FreelancerProfile />}
        />
        <Route path="/edit-job" element={<EditJob />} />
        <Route path="/for-employers" element={<ForEmployeers />} />
        <Route path="/for-job-seekers" element={<ForJobSeekers />} />
        <Route path="/hire-temp-workers" element={<HireFreelancers />} />
        <Route path="/hire-fulltime-employees" element={<HireFullTimeEmployees />} />
        <Route path="/job-searching-assistance" element={<JobSearchingAssistance />} />
        <Route path="/post-a-job" element={<PostJob />} />
        <Route path="/events" element={<Events />} />
        <Route path="/events/:eventId" element={<SingleEvent />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/hrserviceconsulting" element={<HrServiceConsulting />} />
      </Routes>
    </React.Suspense>
  );
}
