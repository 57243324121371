import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyBdXTzir2U8fCQOpz_Ofa06hmzmwQa2XYw",
  authDomain: "nxthike-dev.firebaseapp.com",
  projectId: "nxthike-dev",
  storageBucket: "nxthike-dev.appspot.com",
  messagingSenderId: "832531981490",
  appId: "1:832531981490:web:597cefa5e116b3c42a95ab",
  measurementId: "G-447CR583VN"
};
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const auth = getAuth(app);
export { app, database, auth };
